import { getPreSignedUrl } from '@/apis/preSigned';
import { headObject } from '@/apis/proxy';
import { updateUploadingFile, uploadFile } from '@/store/job';
import moment from 'moment';
import convertToByte from '@/utils/convertToByte';

export const UPLOAD_STATUS = {
  COMPLETED: 'COMPLETED',
  PREPARED: 'PREPARED',
  ERROR: 'ERROR'
};

// const MINIMUM_CHUNK_SIZE = 1024 * 1000 * 200; // 20MB
const MAXIMUM_SINGLE_FILE_UPLOAD_SIZE = 1024 * 1000 * 20; // 5 GB
// const MAXIMUM_MULTIPART_COUNT = 10000; // AWS S3 Multipart 파트 개수는 1만개가 최대이다.

export const startUploadCheck = async (spaceId, projectId, region, bucketName, path, files) => {
  try {
    return await Promise.all(
      files.map((item) => duplicateCheck(spaceId, projectId, region, bucketName, path, item))
    );
  } catch (e) {}
};

const duplicateCheck = async (spaceId, projectId, region, bucketName, path, file) => {
  try {
    let fileName = file.name;
    if (file.hasOwnProperty('path')) {
      if (file.path !== file.name) {
        fileName = file.path.substring(1);
      }
    }
    let status = await headObject({
      spaceId: spaceId,
      projectId: projectId,
      region: region,
      bucketName: bucketName,
      fileName: `${path}${fileName}`
    });

    return {
      status: status,
      file: file,
      path: path
    };
  } catch (e) {}
};

export const startUpload = (
  dispatch,
  spaceId,
  projectId,
  region,
  bucketName,
  path,
  files,
  onCompleted
) => {
  files.forEach(({ file }, index) => {
    let isLastedFile = files.length - 1 === index;
    if (file.size <= MAXIMUM_SINGLE_FILE_UPLOAD_SIZE) {
      startSingleUpload(
        dispatch,
        spaceId,
        projectId,
        region,
        bucketName,
        path,
        file,
        isLastedFile,
        onCompleted
      );
    } else {
      startSingleUpload(
        dispatch,
        spaceId,
        projectId,
        region,
        bucketName,
        path,
        file,
        isLastedFile,
        onCompleted
      );
      // startMultipartUpload(bucketName, path, item, isLastedFile);
    }
  });
};

export const startSingleUpload = (
  dispatch,
  spaceId,
  projectId,
  region,
  bucketName,
  path,
  file,
  isLastedFile,
  onCompleted
) => {
  let fileName = file.name;
  let filePath = path;
  if (file.hasOwnProperty('path')) {
    if (file.path !== file.name) {
      fileName = file.path.substring(1);
      filePath = path + fileName.replace(file.name, '');
    }
  }

  dispatch(
    uploadFile({
      name: file.name,
      path: filePath,
      size: convertToByte(file.size),
      startTime: moment().utc().local().format('YYYY-MM-DD HH:mm:ss'),
      status: UPLOAD_STATUS.PREPARED,
      params: {
        dispatch,
        bucketName,
        region,
        path,
        file,
        isLastedFile
      }
    })
  );
  getPreSignedUrl({
    spaceId: spaceId,
    projectId: projectId,
    bucketName: bucketName,
    fileName: `${path}${fileName}`
  })
    .then((response) => {
      fetch(response, {
        method: 'PUT',
        headers: {
          'access-control-allow-origin': '*',
          'content-length': file.size
        },
        body: file
      })
        .then(() => {
          const uploadFile = {
            name: file.name,
            path: filePath,
            size: convertToByte(file.size),
            completedTime: moment().utc().local().format('YYYY-MM-DD HH:mm:ss'),
            status: UPLOAD_STATUS.COMPLETED
          };
          dispatch(updateUploadingFile(uploadFile));
          if (onCompleted != null) {
            onCompleted(uploadFile);
          }
        })
        .catch(() => {
          dispatch(
            updateUploadingFile({
              name: file.name,
              path: filePath,
              size: convertToByte(file.size),
              completedTime: moment().utc().local().format('YYYY-MM-DD HH:mm:ss'),
              status: UPLOAD_STATUS.ERROR
            })
          );
        });
    })
    .catch(() => {
      dispatch(
        updateUploadingFile({
          name: file.name,
          path: filePath,
          size: convertToByte(file.size),
          completedTime: moment().utc().local().format('YYYY-MM-DD HH:mm:ss'),
          status: UPLOAD_STATUS.ERROR
        })
      );
    });
};

// const startMultipartUpload = (bucketName, path, file, isLastedFile) => {
//   multipartUpload({
//     bucketName: bucketName,
//     path: path,
//     fileName: `${file.name}`
//   })
//     .then((response) => {
//       const { Bucket, Key, UploadId } = response.result.InitiateMultipartUploadResult;
//       const fileSize = files[0].size;
//       let chunkSize = fileSize / MAXIMUM_MULTIPART_COUNT;
//       if (chunkSize < MINIMUM_CHUNK_SIZE) {
//         chunkSize = MINIMUM_CHUNK_SIZE;
//       }
//       const chunkCount = Math.floor(fileSize / chunkSize) + 1;
//
//       getPollMultipartUpload(
//         file,
//         Bucket,
//         `${path}${file.name}`,
//         UploadId,
//         1,
//         chunkCount,
//         isLastedFile
//       );
//     })
//     .catch((e) => {
//       console.log(e);
//     });
// };

// const getPollMultipartUpload = (
//   file,
//   bucketName,
//   fileName,
//   uploadId,
//   partNumber,
//   endPartNumber,
//   isLastedFile
// ) => {
//   getMultiPartPreSignedUrl({
//     bucketName: bucketName,
//     fileName: fileName,
//     uploadId: uploadId,
//     partNumber: partNumber,
//     accountId: projectState.storage.accountId
//   }).then((response) => {
//     let start = (partNumber - 1) * endPartNumber;
//     let end = partNumber * endPartNumber;
//     let blob = partNumber <= endPartNumber ? file.slice(start, end) : file.slice(start);
//     axios
//       .put(response, blob)
//       .then((response) => {
//         if (partNumber === endPartNumber) {
//           if (isLastedFile) {
//             // 새로고침
//           }
//         } else {
//           ++partNumber;
//           getPollMultipartUpload(file, bucketName, fileName, uploadId, partNumber, endPartNumber);
//         }
//       })
//       .catch((e) => {
//         console.log(e);
//       });
//   });
// };
