import { useQuery } from 'react-query';
import { ApiFactory } from '@/utils/apiFactory';
import { exceptionProcessorForQuery } from '@/services/infrastructure/exceptionProcessorForQuery';
import tryCatch from '@/services/infrastructure/tryCatch';

const duplicateDeleteArray = (data) =>
  data.filter(
    (item1, index) =>
      data.findIndex((item2) => item1.code === item2.code && item1.codeType === item2.codeType)
      === index
  );

export const createKeyForGetRoutingPolicy = ({ spaceId, projectId, routingPolicyId }) => [
  `/spaces/${spaceId}/projects/${projectId}/routing-policies/${routingPolicyId}`
];

export const useQueryToGetRoutingPolicyV1 = (
  { spaceId, projectId, routingPolicyId },
  options = null,
  postProcessor = null,
  exceptionHandler = exceptionProcessorForQuery()
) =>
  useQuery(
    createKeyForGetRoutingPolicy({ spaceId, projectId, routingPolicyId }),
    async (context) =>
      tryCatch(async () => {
        const response = await ApiFactory.cpdV3({
          method: 'get',
          url: `/routing-policies/${routingPolicyId}`,
          headers: {
            'cloudplex-space-id': spaceId,
            'cloudplex-project-id': projectId
          },
          signal: context.signal
        });
        response.data.trafficRoutes = duplicateDeleteArray(response.data.trafficRoutes);
        // ToDO: ksk
        if (postProcessor != null) {
          return postProcessor(response.data);
        }
        return response.data;
      }, exceptionHandler),
    { ...options }
  );
