import { ApiFactory } from '@/utils/apiFactory';

export const getPreSignedUrl = async ({ spaceId, projectId, bucketName, fileName }) => {
  let params = {
    url_type: 'uploads',
    method_name: 'put_object',
    http_method: 'PUT',
    params: {
      Bucket: bucketName,
      Key: fileName
    }
  };

  const result = await ApiFactory.cpdV3({
    method: 'post',
    url: `/aws-presigned-url:get`,
    headers: {
      'cloudplex-space-id': spaceId,
      'cloudplex-project-id': projectId
    },
    data: params
  });

  return result.data;
};

export const getMultiPartPreSignedUrl = async ({
  spaceId,
  projectId,
  bucketName,
  fileName,
  uploadId,
  partNumber
}) => {
  try {
    let params = {
      url_type: 'uploads',
      method_name: 'upload_part',
      http_method: 'PUT',
      params: { Bucket: bucketName, Key: fileName, UploadId: uploadId, PartNumber: partNumber }
    };

    const result = await ApiFactory.cpdV3({
      method: 'post',
      url: `/aws-presigned-url:get`,
      headers: {
        'cloudplex-space-id': spaceId,
        'cloudplex-project-id': projectId
      },
      data: params
    });

    return result.data;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const downloadObject = async ({ spaceId, projectId, bucketName, fileName }) => {
  try {
    let params = {
      url_type: 'uploads',
      method_name: 'get_object',
      http_method: 'GET',
      params: { Bucket: bucketName, Key: fileName },
    };

    const result = await ApiFactory.cpdV3({
      method: 'post',
      url: `/aws-presigned-url:get`,
      headers: {
        'cloudplex-space-id': spaceId,
        'cloudplex-project-id': projectId
      },
      data: params
    });

    return result.data;
  } catch (e) {
    console.log(e);
    return null;
  }
};
