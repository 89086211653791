import React, { useCallback, useState } from 'react';
import {
  Typography,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  ClickAwayListener
} from '@mui/material';
import { IconButton } from '@mzc-cloudplex/core';
import {
  IconChevronDown,
  IconChevronUp,
  IconCircle,
  IconCompleteCircle
} from '@mzc-cloudplex/icons';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isEmpty } from '@/utils/utils';
import theme from '@/styles/theme';
import moment from 'moment';
import { useQueryToGetRoutingPolicyV1 } from '@/hooks/queries/useQueryToGetRoutingPolicyV1';
import { useDeliveryLocation } from '@/hooks/useDeliveryLocation';
import { LoadableContainer } from '@/components/common/containers/LoadableContainer';
import { useRoutingPolicyTypeProvider } from '@/hooks/useRoutingPolicyTypeProvider';
import { PaperPanel } from '@/components/layouts/headerPanel/styles/headerPanel.styled';

const HeaderPanel = () => {
  const { spaceId, projectId } = useDeliveryLocation();
  const [expanded, setExpanded] = useState(false);
  const expandPanel = useCallback(() => setExpanded(true), []);
  const closePanel = useCallback(() => setExpanded(false), []);
  const [searchParams] = useSearchParams();
  const routingPolicyId = searchParams.get('routingPolicyId');
  const { get } = useRoutingPolicyTypeProvider();

  const { data: dataToGetRoutingPolicy } = useQueryToGetRoutingPolicyV1(
    { spaceId, projectId, routingPolicyId },
    { enabled: routingPolicyId != null }
  );
  return (
    <PaperPanel open={expanded} elevation={1}>
      <ClickAwayListener onClickAway={closePanel}>
        <Grid container flexDirection={'column'} padding={'0px 40px'}>
          <LoadableContainer loading={dataToGetRoutingPolicy == null} loader={<></>}>
            <Grid item>
              <Grid
                container
                sx={{ height: '54px' }}
                justifyContent={'space-between'}
                alignContent={'center'}
              >
                <Grid item gap={1} sx={{ display: `flex`, alignItems: 'center' }}>
                  <IconCircle size={13} color={'success'} />
                  <Typography fontSize={14} fontWeight={'500'}>
                    {dataToGetRoutingPolicy?.name}
                  </Typography>
                  <Typography
                    fontSize={12}
                    fontWeight={'400'}
                    color={theme.palette.secondary['600']}
                  >
                    {`(${dataToGetRoutingPolicy?.id})`}
                  </Typography>
                  <Divider flexItem orientation={'vertical'} />
                  <Typography fontSize={14} fontWeight={'500'}>
                    {get(dataToGetRoutingPolicy?.type)?.label}
                  </Typography>
                </Grid>
                <Grid item>
                  {expanded ? (
                    <IconButton size={'small'}>
                      <IconChevronUp size={16} onClick={closePanel} />
                    </IconButton>
                  ) : (
                    <IconButton
                      circled
                      size={'small'}
                      sx={{
                        backgroundColor: theme.palette.secondary.main,
                        '&:hover': { backgroundColor: theme.palette.secondary.main }
                      }}
                      onClick={expandPanel}
                    >
                      <IconChevronDown size={16} htmlColor={'white'} />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <RoutingPolicyOverview routingPolicy={dataToGetRoutingPolicy} />
            </Grid>
          </LoadableContainer>
        </Grid>
      </ClickAwayListener>
    </PaperPanel>
  );
};

const RoutingPolicyOverview = ({ routingPolicy }) => {
  const { t } = useTranslation();
  const { description, activities, creator, alias } = routingPolicy;

  return (
    <Grid container flexWrap={`nowrap`}>
      <Grid item xs={4}>
        <List disablePadding>
          <ListItem>
            <ListItemText
              primary={
                <Typography variant={'body2'} color={theme.palette.secondary[600]}>
                  {t(`label.description`)}
                </Typography>
              }
              secondary={<Typography variant={'subtitle2'}>{description ?? '-'}</Typography>}
            />
          </ListItem>
        </List>
      </Grid>
      <Divider flexItem orientation={'vertical'} sx={{ margin: `0 10px` }} />
      <Grid item xs={4}>
        <List disablePadding>
          <ListItem>
            <ListItemText
              disableTypography
              primary={
                <Typography variant={'body2'} color={theme.palette.secondary[600]}>
                  {t('label.applicationStatus')}
                </Typography>
              }
              secondary={
                <Grid container alignItems={'center'} gap={0.75}>
                  <IconCompleteCircle size={12} color={'success'} />
                  <Typography
                    variant={'subtitle2'}
                    component={'span'}
                    color={theme.palette.success.main}
                  >
                    {t('label.Applying')}
                  </Typography>
                </Grid>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <Typography variant={'body2'} color={theme.palette.secondary[600]} mb={1}>
                  {t('label.alias')}
                </Typography>
              }
              secondary={
                <Typography variant={'subtitle2'} color={!alias && theme.palette.text.disabled}>
                  {alias ? alias : t('label.None')}
                </Typography>
              }
            />
          </ListItem>
        </List>
      </Grid>
      <Divider flexItem orientation={'vertical'} sx={{ margin: `0 10px` }} />
      <Grid item xs={4}>
        <List disablePadding>
          <ListItem>
            <ListItemText
              primary={
                <Typography variant={'body2'} color={theme.palette.secondary[600]}>
                  {t('label.createdAt')}
                </Typography>
              }
              secondary={
                <Typography>
                  {!isEmpty(activities)
                    && !isEmpty(activities.find((item) => item.action === 'CREATE'))
                    && moment
                      .utc(activities.find((item) => item.action === 'CREATE')?.occurredAt)
                      .local()
                      .format('YYYY-MM-DD HH:mm:ss')}
                  {` ${t('label.createdBy', {
                    creator: creator?.name
                  })}`}
                </Typography>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <Typography variant={'body2'} color={theme.palette.secondary[600]}>
                  {t('label.updatedAt')}
                </Typography>
              }
              secondary={
                activities.find((item) => item.action === 'LAST_UPDATE') == null ? (
                  <Typography variant={'subtitle2'} color={!alias && theme.palette.text.disabled}>
                    -
                  </Typography>
                ) : (
                  <Typography>
                    {!isEmpty(activities)
                      && !isEmpty(activities.find((item) => item.action === 'LAST_UPDATE'))
                      && moment
                        .utc(activities.find((item) => item.action === 'LAST_UPDATE')?.occurredAt)
                        .local()
                        .format('YYYY-MM-DD HH:mm:ss')}
                    {` ${t('label.updatedBy', {
                      updater: activities.find((item) => item.action === 'LAST_UPDATE')?.actor.name
                    })}`}
                  </Typography>
                )
              }
            />
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
};

export default HeaderPanel;
