const menuService = {
  paths: {
    summary: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/summary`,
    projectUsers: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/users`,
    projectUser: (spaceId, projectId, userId) =>
      `/spaces/${spaceId}/projects/${projectId}/users/${userId}`,
    endpoint: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/endpoint`,
    dns: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/dns`,
    cdns: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/cdns`,
    cdn: (spaceId, projectId, cdnId) => `/spaces/${spaceId}/projects/${projectId}/cdns/${cdnId}`,
    routingPolicies: (spaceId, projectId) =>
      `/spaces/${spaceId}/projects/${projectId}/routing-policies`,
    routingPolicyV1: (spaceId, projectId, routingPolicyId) =>
      `/spaces/${spaceId}/projects/${projectId}/routing-policies/${routingPolicyId}`,
    routingPolicyV2: (spaceId, projectId, routingPolicyId) =>
      `/spaces/${spaceId}/projects/${projectId}/routing-policies/v2/${routingPolicyId}`,
    storage: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/storage`,
    compareJobs: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/compare-jobs`,
    recordJobs: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/record-jobs`,
    purgeJobs: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/purge-jobs`,
    purgeJob: (spaceId, projectId, jobId) =>
      `/spaces/${spaceId}/projects/${projectId}/purge-jobs/${jobId}`,
    compareJob: (spaceId, projectId, jobId) =>
      `/spaces/${spaceId}/projects/${projectId}/compare-jobs/${jobId}`,
    recordJobV4: (spaceId, projectId, jobId) =>
      `/spaces/${spaceId}/projects/${projectId}/record-jobs/${jobId}`,
    recordJobV5: (spaceId, projectId, jobId) =>
      `/spaces/${spaceId}/projects/${projectId}/record-jobs/v5/${jobId}`,
    notifications: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/notifications`,
    notification: (spaceId, projectId, notificationId) =>
      `/spaces/${spaceId}/projects/${projectId}/notifications/${notificationId}`,

    originStorage: (spaceId, projectId) =>
      `/spaces/${spaceId}/projects/${projectId}/origin-storage`,
    cdnMetrics: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/cdn-metrics`,
    cdnMonitoring: (spaceId, projectId) =>
      `/spaces/${spaceId}/projects/${projectId}/cdn-monitoring`,

    realtimeMonitoring: (spaceId, projectId) =>
      `/spaces/${spaceId}/projects/${projectId}/realtime-monitoring`,

    projectHome: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}`,

    spaces: () => '/spaces',
    home: () => '/',

    welcome: () => '/welcome',
    invalidUser: () => '/invalid-user',
    browserNotSupported: () => '/error/browser'
  },
  rules: {
    summary: () => menuService.paths.summary(':spaceId', ':projectId'),
    projectUsers: () => menuService.paths.projectUsers(':spaceId', ':projectId'),
    projectUser: () => menuService.paths.projectUser(':spaceId', ':projectId', ':userId'),
    endpoint: () => menuService.paths.endpoint(':spaceId', ':projectId'),
    dns: () => menuService.paths.dns(':spaceId', ':projectId'),
    cdns: () => menuService.paths.cdns(':spaceId', ':projectId'),
    cdn: () => menuService.paths.cdn(':spaceId', ':projectId', ':cdnId'),
    routingPolicies: () => menuService.paths.routingPolicies(':spaceId', ':projectId'),
    routingPolicyV1: () =>
      menuService.paths.routingPolicyV1(':spaceId', ':projectId', ':routingPolicyId'),
    routingPolicyV2: () =>
      menuService.paths.routingPolicyV2(':spaceId', ':projectId', ':routingPolicyId'),
    storage: () => menuService.paths.storage(':spaceId', ':projectId'),
    purgeJobs: () => menuService.paths.purgeJobs(':spaceId', ':projectId'),
    compareJobs: () => menuService.paths.compareJobs(':spaceId', ':projectId'),
    dnsZoneRecordJobs: () => menuService.paths.recordJobs(':spaceId', ':projectId'),
    purgeJob: () => menuService.paths.purgeJob(':spaceId', ':projectId', ':jobId'),
    compareJob: () => menuService.paths.compareJob(':spaceId', ':projectId', ':jobId'),
    dnsZoneRecordJobV4: () => menuService.paths.recordJobV4(':spaceId', ':projectId', ':jobId'),
    dnsZoneRecordJobV5: () => menuService.paths.recordJobV5(':spaceId', ':projectId', ':jobId'),
    notifications: () => menuService.paths.notifications(':spaceId', ':projectId'),
    notification: () => menuService.paths.notification(':spaceId', ':projectId', ':notificationId'),

    originStorage: () => menuService.paths.originStorage(':spaceId', ':projectId'),
    cdnMetrics: () => menuService.paths.cdnMetrics(':spaceId', ':projectId'),
    cdnMonitoring: () => menuService.paths.cdnMonitoring(':spaceId', ':projectId'),

    realtimeMonitoring: () => menuService.paths.realtimeMonitoring(':spaceId', ':projectId'),

    projectHome: () => menuService.paths.projectHome(':spaceId', ':projectId'),

    spaces: () => menuService.paths.spaces(),
    home: () => menuService.paths.home(),

    welcome: () => menuService.paths.welcome(),
    invalidUser: () => menuService.paths.invalidUser(),
    browserNotSupported: () => menuService.paths.browserNotSupported()
  },
  parse: (pathname) => {
    const paths = pathname.split('/'); // /spaces/123/projects/456 or /admin/spaces/123/projects/456
    const spaceId = paths.length > 2 && paths[0] === '' && paths[1] === 'spaces' ? paths[2] : null;
    const projectId =
      spaceId != null && paths.length > 4 && paths[3] === 'projects' ? paths[4] : null;
    return { spaceId, projectId };
  },
  changePathByProjectId: (path, projectId) => {
    // ToDO: ksk - 여기서 메뉴 구조를 유지하면서 잠시 들려서 이동 될 곳이 필요하다
    // ['', 'spaces', ':spaceId', 'projects', ':projectId', ...] 프로젝트 이동할 때 경로는 6뎁스 유지
    const paths = path.split('/').slice(0, 6);
    paths[4] = projectId;
    return paths.join('/');
  }
};

export default menuService;
